<template>
  <div class="ter">
    <a-button
      class="editable-add-btn"
      @click="handleAdd"
      type="primary"
      v-if="editInfo"

    >
      添加导师信息
    </a-button>
  
    <el-table
      :data="dataSource"
      style="width: 100%"
      max-height="500"
      border
      stripe
      
    >
      <el-table-column  prop="name" label="姓名" :width='scWidth<=800?150:""'  :resizable='false'> 
        <template slot-scope="scope">
          <el-input v-model="scope.row.name" placeholder="请输入姓名"    :disabled="!editInfo" @change='changeFun'></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="手机号" :width='scWidth<=800?150:""'  :resizable='false'>
           <template slot-scope="scope">
          <el-input v-model="scope.row.phone" placeholder="请输入手机号"    :disabled="!editInfo" @change='changeFun'></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="emial" label="邮箱" :width='scWidth<=800?150:""'  :resizable='false'>

           <template slot-scope="scope">
          <el-input v-model="scope.row.email" placeholder="请输入邮箱"    :disabled="!editInfo" @change='changeFun' ></el-input>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="120" fixed='right'  :resizable='false'>
        <template slot-scope="scope">
       
            <a-popconfirm
             v-if="editInfo"
              :title="`确定删除导师${scope.row.name}？`"
              cancelText="取消"
              okText="确定"
              @confirm="() => onDelete(scope.$index, dataSource)"
            >
              <a href="javascript:;">删除</a>
            </a-popconfirm>
              <span v-else>暂无</span>
     
        </template>
      </el-table-column>
    </el-table>
  
    <a-button
      type="primary"
      @click="saveFun"
      :disabled="dis"
      style="margin-top: 20px; margin-right: 20px"
      v-if="editInfo"
  
    >
      保存
    </a-button>
    <a-button type="primary" @click="go" style="margin-top: 20px" v-if="tguo"  >
      跳过
    </a-button>
  </div>
</template>
<script>
// import EditableCell from "@/components/EditableCell.vue";
export default {
  components: {
    // EditableCell,
  },
  data() {
    return {
      id: "",
      editInfo: false,
      dis: true,
      type: "本期",
      widthAdd:'',
      scWidth:document.body.clientWidth,
      dataSource: [
        // {
        //   key: '0',
        //   name: '测试导师',
        //   phone: '测试导师',
        //   email: '测试导师',
        // },
        // {
        //   key: '1',
        //   name: '',
        //   phone: '',
        //   email: '',
        // },
      ],
      count: 0,
  

    };
  },
  mounted() {
        // window.onresize = () => {
        //   if(<800){
        //     this.widthAdd = 150
        //   }
        // }
    let url = window.location.href;
    if (
       window.localStorage.getItem("anjing-info") &&
       window.localStorage.getItem("anjing-info") != "" &&
      url.indexOf("enrollDetail") != -1
    ) {
      this.info = JSON.parse( window.localStorage.getItem("anjing-info"));
      this.editInfo = this.info.edit;
      this.dataSource = this.info.tutors;
      // this.tableData = this.info.tutors;
      this.type = this.info.type;
      this.id = this.info._id;
    } else {
      this.editInfo = true;
    }
    // if(this..length>0){
    //   this.dis = false;
    // }
  },
  props: {
    tguo: Boolean,
  },
  methods: {
    changeFun(){
    this.dis = false;
    },
    // deleteRow(index, rows) {
    //   rows.splice(index, 1);
    // },
    handleEdit(index, row) {
      console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(row);
      this.tableData.splice(index, 1);
    },
    go() {
      this.$emit("btnFun", 3);
    },
    onDelete(index, rows) {
      rows.splice(index, 1);
      this.dis = false;
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
    handleAdd() {
       if(this.dataSource.length>=2){
        this.$message.info('最多可添加两位导师！');
        return;
       }
      const { count, dataSource } = this;
      const newData = {

        name: "",
        phone: "",
        email: "",
      };
      this.dataSource = [...dataSource, newData];
      this.count = count + 1;
      this.dis = false;
    },
    saveFun() {
      let url = window.location.href;
      let id = "";
      if (
         window.localStorage.getItem("anjing-info") &&
         window.localStorage.getItem("anjing-info") != "" &&
        url.indexOf("enrollDetail") != -1
      ) {
        id = this.id;
      } else {
        id =  window.localStorage.getItem("anjing_id");
      }
      console.log(this.dataSource);
      let data = {
        op: "edittutor",
        _id: id,
        list: this.dataSource.length > 0 ? this.dataSource : "",
      };
       
      let _this = this;
      this.$ajax
        .post(this.url + "/signup.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            let url = window.location.href;
            if (
               window.localStorage.getItem("anjing-info") &&
               window.localStorage.getItem("anjing-info") != "" &&
              url.indexOf("enrollDetail") != -1
            ) {
              _this.$message.success(response.data.msg);
            } else {
              _this.$emit("btnFun", 3);
            }
          } else {
            _this.$message.error(response.data.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    edit(val) {
      if (val) {
        this.dis = true;
      }
    },
  },
};
</script>
<style>
.ter {
  margin-top: 20px;
  padding: 0 15px;
}
.editable-cell {
  position: relative;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell-icon {
  line-height: 18px;
  display: none;
}

.editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}

/deep/.ant-table-wrapper {
  border: 1px solid #ededed;
}
</style>